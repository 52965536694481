.customDropdown,
.customDropdown:focus,
.customDropdown:hover {
    background: transparent !important;
    color: #666666 !important;
    border: 0 !important;
    padding: 0 !important;
    box-shadow: none !important;
}

.customDropdown::before,
.customDropdown::after {
    display: none !important;
}

.customDropdownMenu a {
    color: #444;
    font-size: 13px;
    padding: 12px 20px;
}

.customDropdownMenu a:hover,
.customDropdownMenu a:active{
    background-color: #00accd;
    color: #ffffff;
}