
.UserDropdown {
    background: #00accd;
    height: 40px;
    width: 40px;
    border-radius: 20px;
    text-align: center;
    margin-right: 5px;
    margin-top: 3px;
    cursor: pointer;
}

.UserDropdown::before,
.UserDropdown::after {
    display: none !important;
}

.UserDropdown .DropdownText {
    padding-top: 9px;
    font-size: 18px;
    letter-spacing: 1px;
    color: #ffffff;
    font-weight: 500;
}


@media (max-width: 499px) {
    .DesktopOnly {
        display: none;
    }

}


.BorderTop {
    border-top: 1px solid #DDDDDD;
    border-bottom: 0;
}

.Dropdown {
    padding: 0 !important;
    border: 0 !important;
    font-size: 13px;

}

.DropdownMenu {
    box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.2);
    max-height: 250px;
    overflow: auto;
    font-size: 12px;
    left: 0;
    list-style: none outside none;
    padding: 0;
    text-shadow: none;
    z-index: 1000;
    border: 1px solid #888;

}

.Dropdown .UserDropdownMenu {
    width: auto;
    min-width: 200px;
    max-height: 330px;
    margin: 0;
}

.Dropdown .UserDropdownMenu > li > a {
    display: block;
    color: inherit;
    line-height: 25px;
    font-size: 13px;
    padding: 7px 13px;

}

.UserIcon {
    font-size: 28px !important;
    margin-top: -5px;
}

.FontImage {
    margin-right: 2px;
    margin-left: -5px;
    font-size: 18px !important;
    margin-top: -1px;
}

.DropdownMenu > li {
    background-color: #ffffff;
    color: #212121;
    font-size: 12px;
    border-left: 5px solid transparent;
}

.DropdownMenu > li.active,
.DropdownMenu > li:hover {
    background-color: #edeef0;
    border-left: 5px solid #00accd;
}

.DropdownMenu > li > a:hover,
.DropdownMenu > li > a:focus {
    color: #262626;
    text-decoration: none;
    background-color: #f5f5f5;
}

.UserInfo {
    padding: 2px 15px;
    color: #FFF;
    background: #353b40;
    border: 0 solid;
    font-weight: 500;
}

.TenantName {
    padding-left: 17px;
    font-size: 12px;
}
