.MiddleBox {
    max-width: 400px;
    z-index: 100;
    margin: 0 auto;
    height: 600px;
    position: absolute;
    top: calc(50% - 300px);
    width: 350px;
    left: calc(50% - 175px);
    padding: 0;
}

.MiddleBox h1 {
    font-size: 120px;
    margin-top: 0;
}

@media (max-height: 600px) {
    .MiddleBox {
        top: 0;
    }
}

@media (min-height: 800px) {
    .MiddleBox {
        top: 100px;
    }
}
