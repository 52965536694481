body {
    margin: 0;
    font-family: 'Open Sans', sans-serif;
    background-color: #f0f0f0;
    color: #4a4a4a;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/*override css*/
.MuiTabPanel-root {
    padding: 20px 0 !important;
}

.PrivateTabIndicator-colorPrimary-2 {
    background-color: #00accd !important;
}

.MuiTab-textColorPrimary.Mui-selected {
    color: #00accd !important;
}

.MuiFormLabel-root.Mui-focused {
    color: #00accd !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #00accd !important;
}

