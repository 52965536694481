.Primary {
    background-color: #00accd !important;
    border-color: #00accd !important;
    color: #FFFFFF !important;
}

.Primary:hover,
.Primary:focus,
.Primary:active,
.Primary.active,
.Primary:active:focus,
.Primary:active:hover,
.Primary.active:hover,
.Primary.active:focus {
    background-color: #0094b0 !important;
    border-color: #0094b0 !important;
    color: #FFFFFF !important;

}

.Link {
    color: #00accd !important;
}

.WhiteText{
    color: #FFFFFF !important;
}

.BlackText{
    color: #333333 !important;
}