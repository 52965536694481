.ApplicationBG {
    margin-top: 67px;
    min-width: 992px;
    overflow: auto;
}

.ApplicationBG .notification-warning {
    background-color: #f5a623;
}

.ApplicationBG .notification-success {
    background-color: #51a003;
}

.ApplicationBG .notification-error {
    background-color: #bc2b09;
}

.ApplicationBG .notification-info {
    background-color: #00accd;
}

