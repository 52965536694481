.OverlayContent {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 60%;
    z-index: 10014;
    margin-top: -20px;
    margin-left: -30%;
}

.OverlayContainer {
    background: #ffffff none repeat scroll 0 0;
    height: 100%;
    left: 0;
    opacity: 0.85;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9998;
}

.OverlayContent .Text {
    margin-bottom: 100px;
    font-size: 34px;
    text-align: center;
}

.ProgressBar {
    width: 100%;
    height: 3px;
    border: none;
    z-index: 1001;
    top: 64px;
    position: absolute;
    background: rgba(44, 181, 232, 0.26);
}

@keyframes changewidth {
    from {
        margin-left: 0;
    }
    to {
        margin-left: 85%;
    }
}

.ProgressBar .BarSlide {
    width: 15%;
    box-shadow: none;
    animation-duration: 1.5s;
    animation-name: changewidth;
    animation-iteration-count: infinite;
    animation-direction: alternate;

    -webkit-transition: width 350ms;
    -moz-transition: width 350ms;
    -o-transition: width 350ms;
    transition: width 350ms;
    height: 100%;
    border-radius: 2px;
    background: linear-gradient(to right, #00accd 0%, #0585a7 51%, #02657f 75%);

}