.LoginBG {
    background-color: #07758c;
    background-image: url('../../assets/images/yotascale-bg.jpg');
    background-attachment: fixed;
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100%;
    width: 100%;
    position: fixed;
    overflow: auto;
    text-align: center;
}


