.TopSubMenu{
    background-color: #ffffff;
    padding: 10px 20px;
    font-weight: 700;
    font-size: 13px;
    color: #353b40;

}

.customBreadCrumb {
    list-style: none;
    border-radius: 4px;
    background-color: #ffffff;
    padding: 0;
    margin-bottom: 5px;
}

.customBreadCrumb > li {
    display: inline-block;
}

.customBreadCrumb li a.customBreadCrumbLink {
    color: #00accd;
    border-bottom: 4px solid #00accd;
    padding-bottom: 5px;
}
.customBreadCrumb > li a {
    color: rgb(103, 106, 108);
    text-decoration: none;
}


.customBreadCrumb > li + li::before {
    content: "| ";
    padding: 0 5px;
    color: #ccc;
}