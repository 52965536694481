.Logo {
    color: #e6e6e6;
    font-size: 180px;
    font-weight: 800;
    letter-spacing: -10px;
    margin-bottom: 0px;
}

.YotascaleLogo {
    width: 298px;
    height: 84px;
    object-fit: contain;
}

.YotascaleLogoTagline {
    width: 310px;
    height: 30px;
    object-fit: contain;
}