.ibox {
    clear: both;
    margin-bottom: 25px;
    margin-top: 0;
    padding: 0;
    border-left: solid 1px rgba(0, 0, 0, 0.12);
    border-right: solid 1px rgba(0, 0, 0, 0.12);
    border-bottom: solid 1px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 5px 10px 0px #CCCCCC;
    overflow-y: auto;
    background: white;
}


.iboxTitle {
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    border-color: #e7eaec;
    border-top-color: rgb(231, 234, 236);
    border-image: none;
    border-style: solid solid none;
    border-width: 3px 0 0;
    color: inherit;
    margin-bottom: 0;
    padding: 7px 15px 0;
    background-color: #5e6367;
    font-size: 16px;
    border-top: none;
    min-height: 35px !important;
}



.iboxTitle h5 {
    display: inline-block;
    margin: 0 0 7px;
    padding: 0;
    text-overflow: ellipsis;
    float: left;
    font-size: 18px;
    font-weight: lighter;
    color: #ffffff;
}


.iboxContent {
    clear: both;
    background-color: #ffffff;
    color: inherit;
    padding: 15px 20px 20px 20px;
    border-color: #e7eaec;
    border-image: none;
    border-style: solid solid none;
    border-width: 1px 0;
}