.Input {
    width: 100%;
    margin: 15px auto;
    box-sizing: border-box;
    border-radius: 3px;
}

.Label {
    font-weight: bold;
    display: block;
    margin-bottom: 8px;
}

.InputElement {
    outline: none;
    border: 2px solid #ccc;
    background-color: white;
    font: inherit;
    padding: 6px 10px;
    display: block;
    width: 100%;
    box-sizing: border-box;
    border-radius: 4px;

}

.InputElement:focus {
    outline: none;
    background-color: #EEEEEE;
}

.Disabled {
    outline: none;
    background-color: #CCCCCC;

}
.Invalid {
    border: 2px solid #db0808;
    box-shadow: none;
    /*background-color: #FDA49A;*/
}