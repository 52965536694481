.Navigation {
    height: 67px;
    padding: 0 20px 0 10px !important;
}

@media (max-width: 992px) {
    .Navigation {
        position: absolute !important;
        width: 992px;
    }
}

.Navigation nav {
    height: 100%;
}


.BackLink {
    font-weight: 100;
    color: #DDDDDD !important;
    font-size: 18px;

}
